<template>
    <div class="sabc_date_slider_outer">
        <div class="sabc_date_slider_inner">
          <a href="javascript:;" class="flex-nav-prev" @click="generateDatesNew('back')" aria-label="nav prev"></a>
          <section class="slider">
              <div class="flexslider carousel">
                  <ul class="slides">
                      <li>
                          <router-link :to="{ name: 'football_listing', params: { type: 'all', date:  selectedDate } }">
                      <span>
                          {{ $t(days[getDay()]) }} {{ selectedDate | formatDate('DD/MM') }}
                      </span>
                          </router-link>
                      </li>
                  </ul>
              </div>
          </section>
          <a href="javascript:;" class="flex-nav-next" @click="generateDatesNew('forward')" aria-label="nav next"></a>
        </div>
        <div  v-click-outside="closeCalendar" >
            <div class="sabc_date_slider_icon" @click.prevent="showCalendar = ! showCalendar">
            </div>
            <div  class="calendar-wrap" v-if="showCalendar">
                <span class="close-calender" @click="closeCalendar"><i class="ico-close"></i></span>
                <datepicker :inline="true" v-model="selectedDate" @input="updateDate()"></datepicker>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "date-slider",
        components: {
            'datepicker': () => import('vuejs-datepicker')
        },

        data() {
            return {
                dayNum: 2,
                days : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
                selectedDate: moment().format('YYYY-MM-DD'),
                showCalendar: false,
            }
        },
        serverPrefetch() {
            this.main();
            return true;
        },
        mounted() {
            this.main();
        },
        methods: {
            main() {
                if (this.$route.params.date != undefined) {
                  this.selectedDate = moment(this.$route.params.date).format('YYYY-MM-DD');
                } else {
                  this.selectedDate = moment().format('YYYY-MM-DD');
                }
            },
            generateDatesNew(dir) {
              if(dir === 'back') {
                this.selectedDate = moment(this.selectedDate).add(-1, 'days').format('YYYY-MM-DD');
              } else {
                this.selectedDate = moment(this.selectedDate).add(1, 'days').format('YYYY-MM-DD');
              }
              this.$router.push({ name: 'football_listing', params: { type: 'all',date: this.selectedDate } })
            },
            getDay(){
              return new Date(this.selectedDate).getDay();
            },
            closeCalendar() {
                this.showCalendar = false
            },
            updateDate() {
                this.selectedDate = new Date(this.selectedDate).toISOString().slice(0, 10);
                this.$router.push({name: 'football_listing', params: { type: 'all',date: this.selectedDate } })

                this.closeCalendar()
                if (isBrowser)
                    this.closeCalendar()
            },
        }
    }
</script>
