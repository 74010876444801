<template>
    <div class="cricket_matches_tabs">
        <ul>
            <li :class="(this.$route.params.type == 'all') ? 'selected' : ''">
                <router-link :to="{ name: 'football_listing', params: { type: 'all', date: selectedDate } }">
                    <span class="all_tournament_tab">{{ $t("football.All") }} ({{$getSafe(()=>count.all)}})
                        <samp >
                        </samp>
                    </span>
                </router-link>
            </li>
            <li :class="(this.$route.params.type == 'live') ? 'selected' : ''">
                <router-link :to="{ name: 'football_listing', params: { type: 'live', date: selectedDate } }">
                    <span>Live Matches ({{$getSafe(()=>count.live)}})
                        <samp >
                        </samp>
                    </span>
                </router-link>
            </li>
            <li :class="(this.$route.params.type == 'finished') ? 'selected' : ''">
                <router-link :to="{ name: 'football_listing', params: { type: 'finished', date:  selectedDate } }">
                    <span>Results ({{$getSafe(()=>count.finished)}})
                        <samp >
                        </samp>
                    </span>
                </router-link>
            </li>
            <li :class="(this.$route.params.type == 'upcoming') ? 'selected' : ''">
                <router-link :to="{ name: 'football_listing', params: { type: 'upcoming', date:  selectedDate } }">
                    <span>
                        Fixtures ({{$getSafe(()=>count.upcoming)}})
                        <samp >
                        </samp>
                    </span>
                </router-link>
            </li>
            <li :class="{ selected: $route.name == 'football.myMatches' }">
                <router-link :to="{ name: 'football.myMatches', params: { date:  selectedDate} }">
                    <span>
                        {{ $t("football.My matches") }} ({{ $store.getters.fbFavouriteMatches.length }})
                    </span>
                </router-link>
            </li>
            <li :class="{ selected: $route.name == 'football.tables' }">
                <router-link :to="{name: 'football.tables'}">
                    <span>
                        Tables
                    </span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "ListingTab",
        data(){
            return {
                url:'',
                count:null,
                selectedDate: moment().format("YYYY-MM-DD"),
                countInterval: null
            }
        },
        mounted() {
            if(this.$route.params.date == undefined) {
                this.selectedDate = moment().format("YYYY-MM-DD")
            } else {
                this.selectedDate = this.$route.params.date;
            }
            this.getMatchCount();
            this.countInterval = setInterval(() => {
                this.getMatchCount();
            }, 600000);
        },
        beforeDestroy() {
            clearInterval(this.countInterval);
        },
        watch: {
            '$route.params.type'() {
                this.getMatchCount();
            }
        },
        serverPrefetch() {
            return this.getMatchCount();

        },
        methods:{
            getMatchCount() {
                if(this.$route.params.date == undefined) {
                    this.$route.params.date = moment().format("YYYY-MM-DD")
                }
                this.url=this.$FB_MATCH_COUNT_API+'?date='+this.$route.params.date;
                return this.$axios.get(this.url)
                    .then(response => {
                        this.count = response.data
                    })
                    .catch(error => console.log(error))
            },
        }
    }
</script>
